import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import { Profile } from "@planetadeleste/vue-mc-shopaholic";
import { AuthModule } from "@/store/auth";

const TypedModelMixin = ModelMixin as unknown as VueClass<ModelMixin<Profile>>;

@Component
export default class Account extends Mixins(TypedModelMixin) {
  created() {
    this.obModelClass = Profile;
    this.sRouteName = "account";
  }

  storeUserData(obData: Record<string, any>) {
    AuthModule.storeUser(obData);
    this.$user.set(this.user);
  }

  saved(obResponse: Record<string, any>) {
    if (obResponse.data) {
      this.storeUserData(obResponse.data);
    }
  }

  get user() {
    return AuthModule.user;
  }
}
