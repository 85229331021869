<template>
  <v-form class="fill-height" v-if="obModel">
    <gw-form-observer @save="save" @cancel="cancel" :loading="isLoading">
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <sheet color="grey lighten-4">
            <v-row>
              <v-col cols="12" xl="8">
                <images-upload
                  v-model="obModel"
                  hide-images
                  hide-label
                  preview-label="avatar"
                  preview-value="avatar"
                  lg="12"
                  name="avatar"
                  ref="fAvatar"
                />
              </v-col>
              <v-col cols="12" xl="4" class="text-right">
                <h5>{{ $t("modify.image") }}</h5>
                <v-btn
                  depressed
                  small
                  color="primary"
                  :disabled="!!$_.get(obModel, 'avatar')"
                  @click="$refs.fAvatar.browse()"
                >
                  {{ $t("upload.image") }}
                </v-btn>
                <v-btn
                  depressed
                  small
                  color="primary"
                  :disabled="!$_.get(obModel, 'avatar')"
                  @click="$refs.fAvatar.remove()"
                  class="mt-md-2"
                >
                  {{ $t("remove.image") }}
                </v-btn>
              </v-col>
            </v-row>
          </sheet>
        </v-col>
        <v-col cols="12" md="6" lg="8">
          <v-row>
            <v-col cols="12" lg="6">
              <form-field-text v-model="obModel.name" required />
            </v-col>
            <v-col cols="12" lg="6">
              <form-field-text
                v-model="obModel.last_name"
                label="last.name"
                rules=""
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-field-email v-model="obModel.email" />
            </v-col>
            <v-col cols="12" md="6">
              <form-field-text v-model="obModel.phone" label="phone" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <form-field-passwords v-model="obModel" />
    </gw-form-observer>
  </v-form>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import AccountMixin from "@/modules/account/mixins/Account";
import FormFieldPasswords from "@/components/form/fields/Passwords.vue";

@Component({
  components: { FormFieldPasswords },
})
export default class AccountForm extends Mixins(AccountMixin) {}
</script>
