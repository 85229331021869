<template>
  <module-container>
    <account-form v-model="obModel" />
  </module-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import AccountForm from "@/modules/account/components/Form.vue";
import { Profile } from "@planetadeleste/vue-mc-shopaholic";
import { AuthModule } from "@/store/auth";

@Component({
  components: {
    AccountForm,
  },
})
export default class Account extends Vue {
  obModel: Profile = new Profile();

  mounted() {
    this.obModel = AuthModule.user.clone();
  }
}
</script>
